import React, { useEffect, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import UserService from "../../services/users.service";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import { useSelector } from "react-redux";
import { rolesState } from "../../pages/users/users-slice";

const UserAddView = ({ closeAddUserHandler, usersUpdate }) => {
  const rolesList = useSelector(rolesState);

  const [values, setValues] = useState({});

  const [roleValue, setRoleValue] = useState([]);
  const [error, setErrorState] = useState({});
  const [btnNotDisabled, setBtnNotDisabled] = useState(false);
  const mandatoryFields = ["firstName", "lastName", "mobile", "email", "password"];

  useEffect(() => {
    const filledItems = mandatoryFields.filter((item) => !(item in values) || !values?.[item]);
    setBtnNotDisabled(!filledItems?.length && !Object.keys(error)?.length && !!roleValue?.length)
  }, [values, roleValue, error])

  const handleChange = (event) => {
    const { id, value } = event?.target || {};
    let updatedValue = value;
    if (id === "email") {
      if (!FeUtils.validateEmail(value)) {
        setErrorState(prevState => ({ ...prevState, email: "Enter valid email" }));
      } else {
        const { email , ...rest } = error;
        setErrorState(rest);
      }
    }

    if (id === "mobile") {
      if (value[0] !== "+") {
        updatedValue = `+${value}`;
      }
      if (!FeUtils.phoneNumberValidation(updatedValue)) {
        setErrorState(prevState => ({ ...prevState, mobile: "Enter valid mobile" }));
      } else {
        const { mobile , ...rest } = error;
        setErrorState(rest);
      }
    }
    setValues({ ...values, [id]: updatedValue });
  };

  const handleChangeRoles = (id, name) => {
    if (roleValue?.some(item => item.id === id)) {
      const newRolesList = roleValue.filter((item) => id !== item.id);
      setRoleValue(newRolesList);
    } else {
      setRoleValue(oldArray => [...oldArray, {id, name}]);
    }
  };

  const saveHandler = async (e) => {
    e.preventDefault();

    const sendValues = { ...values, roles: roleValue };
    try {
      await UserService.addUser(sendValues);
      usersUpdate();
      closeAddUserHandler();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="profileDialog">
      <React.Fragment>
        <header className="profileDialog__header">
          <div className="profileDialog__title">
            <h4>Add New User</h4>
          </div>
          <div>
            <IconButton
              aria-label="close dialog"
              className="profileDialog__close"
              onClick={() => closeAddUserHandler("")}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </header>

        <div className="profileDialog__container">
          <form className="profileDialog__form" onSubmit={saveHandler}>
            <TextField
              required
              id="firstName"
              label={"First Name"}
              value={values?.firstName}
              onChange={handleChange}
            />
            <TextField
              required
              id="lastName"
              label={"Last Name"}
              value={values?.lastName}
              onChange={handleChange}
            />
            <TextField
              required
              id="mobile"
              label={"Mobile"}
              value={values?.mobile}
              onChange={handleChange}
              error={error?.mobile}
              helperText={error?.mobile ? error?.mobile : ""}
              placeholder="+..."
            />
            <TextField
              required
              id="email"
              label={"Email"}
              value={values?.email}
              onChange={handleChange}
              error={error?.email}
              helperText={error?.email ? error?.email : ""}
            />
            <TextField
              required
              id="password"
              label={"Temporary Password"}
              value={values?.password}
              onChange={handleChange}
            />
            <FormControl component="fieldset" className="profileDialog__roles">
              <FormLabel component="legend">Roles</FormLabel>
              <FormGroup aria-label="roles">
                {rolesList?.map((item) => {
                  return (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          name={item.id}
                          checked={roleValue?.some((i) => i.id === item.id)}
                          onChange={() => handleChangeRoles(item.id, item.name)}
                        />
                      }
                      label={item.name}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>

            <div className="profileDialog__btn_container">
              <Button
                className="profileDialog__btn"
                variant="contained"
                color="primary"
                type="submit"
                disabled={!btnNotDisabled}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </React.Fragment>
    </div>
  );
};

export default UserAddView;
