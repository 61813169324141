import React, { useEffect, useState } from "react";
import PageLayout from "../../components/page-layout/page-layout";
import UsersService from "../../services/users.service";
import TableElement from "../../components/TableElement/TableElement";
import { useDispatch, useSelector } from "react-redux";
import {usersState, addUsers, setRoles, rolesState } from "./users-slice";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import EmptyListElement from "../../components/EmptyListElement/EmptyListElement";
import UserSingleView from "../../components/user-single-view/user-single-view";
import { Button } from "@material-ui/core";
import UserAddView from "../../components/user-add-view/user-add-view";
import Pagination from '../../components/Pagination/Pagination';
import GlobalAppConstant from '../../constants/globalAppConstant';
import RolesService from '../../services/roles.service';

const UsersComponent = () => {
  const tableCells = {
    firstName: { name: "Name" },
    lastName: { name: "Last Name" },
    status: { name: "Status" },
    roles: { name: "Role" },
    email: { name: "Email" },
    mobile: { name: "Mobile" },
  };

  const dispatch = useDispatch();

  const tableData = useSelector(usersState);
  const rolesList = useSelector(rolesState);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [userAccount, setUserAccount] = useState("");
  const [addUserForm, setAddUserForm] = useState(false);
  const [userAccountState, setUserAccountState] = useState(false);

  const [paginationState, setPaginationState] = useState(0);
  const [usersUpdate, setUsersUpdate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = {
        filter: {
          limit: GlobalAppConstant.defaultTableRows,
          skip: paginationState
        }
      }
      try {
        const response = await UsersService.getUsersList(filterData);
        dispatch(addUsers(response));
        setDataLoaded(true);
      } catch (e) {
        console.error(e);
        setDataLoaded(true);
      }
    };

    fetchData();
  }, [usersUpdate, paginationState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roles = await RolesService.getRoles();
        dispatch(setRoles(roles));
      } catch (e) {
        console.error(e);
      }
    };
    if (!rolesList?.length) {
      fetchData();
    }
  }, []);

  /**
   * Table Row Click Handler.
   * @param id
   */
  const tableRowClickHandler = (id) => {
    setUserAccount(id);
    setUserAccountState(true);
  };

  const paginationClickHandler = async (key) => {
    if (key === 'next') {
      setPaginationState(paginationState + GlobalAppConstant.defaultTableRows)
    }

    if (key === 'prev') {
      setPaginationState(paginationState - GlobalAppConstant.defaultTableRows)
    }
  };

  return (
    <PageLayout>
      <div className="top-calls">
        <div className="top-calls__page-title">Manage Users</div>
        <Button
          className="add-button"
          onClick={() => setAddUserForm(true)}
          variant="contained"
          color="primary"
        >
          + Add user
        </Button>
      </div>

      {userAccountState && (
        <UserSingleView
          id={userAccount}
          usersUpdate={() => setUsersUpdate(!usersUpdate)}
          closeSingleUserHandler={() => setUserAccountState(false)}
        />
      )}
      {addUserForm && (
        <UserAddView
          closeAddUserHandler={() => setAddUserForm(false)}
          usersUpdate={() => setUsersUpdate(!usersUpdate)}
        />
      )}
      {!!dataLoaded && (
        <>
          {Object.keys(tableData).length > 0 && (
            <TableElement
              tableData={tableData}
              tableRowClickHandler={tableRowClickHandler}
              tableCells={tableCells}
            />
          )}
          {!Object.keys(tableData).length && (
            <EmptyListElement text="No Users Found" />
          )}
          <Pagination
            paginationState={paginationState}
            tableData={tableData}
            paginationClickHandler={paginationClickHandler}
          />
        </>
      )}
      {!dataLoaded && <BackdropElement />}
    </PageLayout>
  );
};

export default UsersComponent;
