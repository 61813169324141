import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState: {
    users: [],
    roles: [],
  },
  reducers: {
    addUsers: (state, data) => {
      state.users = data.payload;
    },
    setRoles: (state, data) => {
      state.roles = data.payload;
    },
  },
});

export const usersState = (state) => state.usersSlice.users;
export const rolesState = (state) => state.usersSlice.roles;

export const { addUsers, setRoles } = usersSlice.actions;

export default usersSlice.reducer;
