/**
 * @file
 * Contains Auth service.
 */

import { pureClient, apiClient } from "./apiClients.service";
import Bridge from "../bridge";
import {
  RESTORE_PASSWORD_CODE_ENDPOINT,
  RESTORE_PASSWORD_ENDPOINT,
  REFRESH_TOKEN, WORKCENTER_LINK, ANALYTICS_LINK,
} from "../constants/apiEnpoints.constant";
import urls from "../routes/urls";
import FeUtils from "@fe-sales/common-utils/fe-utils";

/**
 * POST request to Login API.
 * @param {string} url.
 * API url for request.
 * @param {object} data.
 * Request data.
 */
const loginApi = async (url, data) => {
  try {
    const response = await pureClient.post(url, data);
    if (response?.data?.result?.code == 200) {
      Bridge.saveJwtToken(response?.data?.access_token);
      Bridge.saveJwtTokenExpires(response?.data?.expires_in);
      Bridge.saveJwtRefreshToken(response?.data?.refresh_token);
      Bridge.saveJwtRefreshTokenExpires(response?.data?.refresh_expires_in);
      return response?.data?.result;
    } else {
      return response?.data?.result;
    }
  } catch (e) {
    throw new Error(e.data.error.message);
  }
};

/**
 * POST request to Sign up API.
 * @param {string} url.
 * API url for request.
 * @param {object} data.
 * Request data.
 */
const postToSignUpApi = async (url, data) => {
  try {
    return await pureClient.post(url, data);
  } catch (e) {
    console.error(e);
  }
};

/**
 * PUT request to Sign up API.
 * @param {string} url.
 * API url for request.
 * @param {object} data.
 * Request data.
 */
const putToSignUpApi = async (url, data) => {
  const response = await pureClient.put(url, data);
  return response.data;
};

/**
 * PUT request to Sign in API.
 * @param {string} url.
 * API url for request.
 * @param {object} data.
 * Request data.
 */
const putToSignInApi = async (url, data) => {
  const config = {
    headers: {
      "content-type": "application/json",
      authorization: Bridge.getJwtToken(),
    },
  };
  const response = await pureClient.put(url, data, config);
  return response.data;
};

/**
 * POST request to get restore code API.
 * @param {string} email.
 * email for request.
 */
const sendRestoreCodeApi = async (email) => {
  try {
    const res = await pureClient.post(RESTORE_PASSWORD_CODE_ENDPOINT, {
      email,
    });
    console.log(res);
  } catch (e) {
    console.error(e);
  }
};

/**
 * POST request to get restore code API.
 * @param {object} data.
 */
const restorePasswordApi = async (data) => {
  try {
    const res = await pureClient.put(RESTORE_PASSWORD_ENDPOINT, data);
    console.log(res);
  } catch (e) {
    console.error(e);
  }
};

/**
 * POST request to restore JWT token.
 */
const restoreJwtToken = async () => {
  const refreshToken = Bridge.getJwtRefreshToken();
  const refreshExpTime = Bridge.getJwtRefreshTokenExpires();
  const todayDate = new Date().getTime();

  if (todayDate < refreshExpTime) {
    try {
      const response = await pureClient.post(REFRESH_TOKEN, {token: refreshToken});
      Bridge.saveJwtToken(response?.data?.access_token);
      Bridge.saveJwtTokenExpires(response?.data?.expires_in);
      Bridge.saveJwtRefreshToken(response?.data?.refresh_token);
      Bridge.saveJwtRefreshTokenExpires(response?.data?.refresh_expires_in);
    } catch (e) {
      console.error(e);
    }
  } else {
    window.location.href = urls.public.login;
  }
};

const handleAppRedirect = async (profileResponse) => {
  const roles = FeUtils.rolesRule(profileResponse?.roles);
  if (roles?.length && roles?.includes('workcenter')) {
    window.location.href = WORKCENTER_LINK;
    return { redirect: true };
  } else if (roles?.length && !roles?.includes('sales') && roles?.includes('analytics')) {
    window.location.href = ANALYTICS_LINK;
    return { redirect: true };
  }
  return { redirect: false };
}

export default {
  loginApi,
  postToSignUpApi,
  putToSignUpApi,
  putToSignInApi,
  sendRestoreCodeApi,
  restorePasswordApi,
  restoreJwtToken,
  handleAppRedirect
};
