/**
 * @file
 * Contains Call Log component.
 */

import React, { useEffect, useState } from "react";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import "./CallLogs.scss";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../components/page-layout/page-layout";
import TableElement from "../../components/TableElement/TableElement";
import EmptyListElement from "../../components/EmptyListElement/EmptyListElement";
import { callLogsState, addCallLogs } from "./call-logs-slice";
import { Button } from "@material-ui/core";
import CallLogsService from "../../services/callLogs.service";
import CallLogsFilter from "./filter/CallLogsFilter";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import GlobalAppConstant from '../../constants/globalAppConstant';

const CallLogs = (props) => {
  const tableCells = {
    dateCreated: {
      name: "Date",
      extra: "dates",
      format: "DD MMM YYYY, hh:mm a",
    },
    direction: { name: "Direction" },
    from: { name: "From" },
    to: { name: "To" },
    type: { name: "Type" },
    status: { name: "Status" },
    recording: { name: "Recording" },
    duration: { name: "Duration", extra: "time" },
  };

  const dispatch = useDispatch();

  const tableData = useSelector(callLogsState);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [filterState, setFilterState] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [paginationState, setPaginationState] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const requestParams = {
        pageNumber: 0,
        pageSize: GlobalAppConstant.defaultTableRows,
        ...(Object.keys(filterData).length && { ...filterData }),
      };
      try {
        const response = await CallLogsService.getCallLogsList(requestParams);
        dispatch(addCallLogs(response?.list));
        setPaginationState(response?.page);
      } catch (e) {
        console.error(e);
      } finally {
        setDataLoaded(true);
      }
    };

    fetchData();
  }, [dispatch, filterData]);

  const tableRowClickHandler = (id) => {
    console.log(id);
  };

  const closeFilterHandler = (data) => {
    setFilterState(false);
  };

  const filterDataHandler = (data) => {
    setFilterData(data);
  };

  const closeFilterItemHandler = (key) => {
    let newList = { ...filterData };
    if (key === "date") {
      delete newList["startTime"];
      delete newList["endTime"];
    } else {
      delete newList[key];
    }
    setFilterData(newList);
  };

  const paginationClickHandler = async (key) => {
    const requestParams = {
      pageNumber: (key === 'next') ? paginationState?.nextPage?.pageNumber : paginationState?.previousPage?.pageNumber,
      pageToken: (key === 'next') ? paginationState?.nextPage?.pageToken : paginationState?.previousPage?.pageToken,
      pageSize: GlobalAppConstant.defaultTableRows,
      ...(Object.keys(filterData).length && { ...filterData }),
    };
    try {
      const response = await CallLogsService.getCallLogsList(requestParams);
      dispatch(addCallLogs(response?.list));
      setPaginationState(response?.page);
    } catch (e) {
      console.error(e);
    } finally {
      setDataLoaded(true);
    }
  };

  return (
    <PageLayout>
      {filterState && (
        <CallLogsFilter
          closeFilterHandler={closeFilterHandler}
          filterData={filterDataHandler}
          returnFilterData={filterData}
          resetFilters={() => setFilterData({})}
        />
      )}
      <div className="top-calls">
        <div className="top-calls__page-title">
          Manage Call logs made to lead or account.
        </div>
        <div className="top-calls__fiter-wrap">
          {!!Object.keys(filterData).length && (
            <Button
              className="clear-filter-btn"
              onClick={() => setFilterData({})}
              color="primary"
            >
              Clear All Filters
            </Button>
          )}
          <Button
            className="filter-btn"
            onClick={() => setFilterState(true)}
            variant="contained"
            color="primary"
          >
            Filter
          </Button>
        </div>
      </div>
      {!!Object.keys(filterData).length && (
        <div className="top-calls__filters">
          <div className="top-calls__filters__title">Filters :</div>
          {("startTime" in filterData || "endTime" in filterData) && (
            <div className="top-calls__filters__item">
              {`Date Range : `}
              {filterData?.startTime ? (
                FeUtils.formatDateTime(filterData?.startTime, "MM/DD/yyyy")
              ) : (
                <span>&nbsp;&infin;</span>
              )}
              {` - `}
              {filterData?.endTime ? (
                FeUtils.formatDateTime(filterData?.endTime, "MM/DD/yyyy")
              ) : (
                <span>&nbsp;&infin;</span>
              )}
              <i
                className="icons8-cancel top-calls__icon"
                onClick={() => closeFilterItemHandler("date")}
              />
            </div>
          )}
          {Object.entries(filterData).map(([key, value], i) => {
            const mapping = {
              from: "Call From",
              to: "Call To",
              status: "Status",
              queued: "Queued",
              ringing: "Ringing",
              "in-progress": "In progress",
              completed: "Completed",
              busy: "Busy",
              failed: "Failed",
              "no-answer": "No answer",
              canceled: "Canceled",
            };

            if (key !== "startTime" && key !== "endTime") {
              let content = "";
              if (key === "status") {
                content = `${mapping[key]} : ${mapping[value]}`;
              } else {
                content = `${mapping[key]} : ${value}`;
              }
              return (
                <div className="top-calls__filters__item" key={key}>
                  {content}
                  <i
                    className="icons8-cancel top-calls__icon"
                    onClick={() => closeFilterItemHandler(key)}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
      <div className="top-calls__table-wrap">
        {dataLoaded ? (
          Object.keys(tableData).length ? (
            <>
              <TableElement
                tableData={tableData}
                tableRowClickHandler={tableRowClickHandler}
                tableCells={tableCells}
              />
              <div className="top-calls__pagination">
                <i
                  className={`icons8-chevron-left ${!paginationState?.previousPage && 'disabled'}`}
                  onClick={() => paginationClickHandler('prev')}
                />
                <i
                  className={`icons8-chevron-right ${!paginationState?.nextPage && 'disabled'}`}
                  onClick={() => paginationClickHandler('next')}
                />
              </div>
            </>
          ) : (
            <EmptyListElement text="Call logs are empty" />
          )
        ) : (
          <BackdropElement />
        )}
      </div>
    </PageLayout>
  );
};

export default CallLogs;
