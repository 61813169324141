import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "appSlice",
  initialState: {
    url: '',
    redirect: false,
  },
  reducers: {
    updateUrl: (state, data) => {
      state.url = data.payload;
    },
    setRedirectState: (state, data) => {
      state.redirect = data.payload;
    },
  },
});

export const audioPlayerSliceUrl = (state) => state.appSlice.url;
export const redirectState = (state) => state.appSlice.redirect;

export const { updateUrl, setRedirectState } = appSlice.actions;

export default appSlice.reducer;
